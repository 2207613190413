@font-face {
    font-family: "Montserrat";
    font-weight: 100;
    src: url(./fonts/Montserrat/Montserrat-Thin.ttf) format("truetype");
    font-display: swap;
}
@font-face {
    font-family: "Montserrat";
    font-weight: 200;
    src: url(./fonts/Montserrat/Montserrat-ExtraLight.ttf) format("truetype");
    font-display: swap;
}
@font-face {
    font-family: "Montserrat";
    font-weight: 300;
    src: url(./fonts/Montserrat/Montserrat-Light.ttf) format("truetype");
    font-display: swap;
}
@font-face {
    font-family: "Montserrat";
    font-weight: 400;
    src: url(./fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
    font-display: swap;
}
@font-face {
    font-family: "Montserrat";
    font-weight: 500;
    src: url(./fonts/Montserrat/Montserrat-Medium.ttf) format("truetype");
    font-display: swap;
}
@font-face {
    font-family: "Montserrat";
    font-weight: 600;
    src: url(./fonts/Montserrat/Montserrat-SemiBold.ttf) format("truetype");
    font-display: swap;
}
@font-face {
    font-family: "Montserrat";
    font-weight: 700;
    src: url(./fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
    font-display: swap;
}
@font-face {
    font-family: "Montserrat";
    font-weight: 800;
    src: url(./fonts/Montserrat/Montserrat-ExtraBold.ttf) format("truetype");
    font-display: swap;
}
@font-face {
    font-family: "Montserrat";
    font-weight: 900;
    src: url(./fonts/Montserrat/Montserrat-Black.ttf) format("truetype");
    font-display: swap;
}

body {
    margin: 0;
    font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background: #00775f url('bg.png') bottom no-repeat !important;
  background-position-x: left !important;
  min-height: 100vh; */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    background: #ffffff;
    padding: 0 5px;
}

.MuiOutlinedInput-root {
    border-radius: 12px !important;
}
