.mainPartners {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: center;
    grid-gap: 20px;
    border-radius: 10px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
    padding: 20px;
    transition: 0.25s ease-out;
}

.mainPartners > div span {
    color: inherit;
}
.mainPartners > div div span {
    font-size: 1rem;
}

.mainPartners > div {
    transition: 0.25s ease-out;
    opacity: 0.85;
    color: var(--kdwsite-color-darkgrey);
    border: 1px solid var(--kdwsite-color-grey);
}

.mainPartners > div:hover {
    opacity: 1;
}

.mainPartners > div:nth-child(3n + 1) {
    grid-column: 1 / 3;
}

.mainPartners > div:nth-child(3n + 2) {
    grid-column: 3 / 5;
}

.mainPartners > div:nth-child(3n + 3) {
    grid-column: 5 / 7;
}
/*VK и ТТС комменчу пока */
/*VK */
/* .mainPartners > div:nth-child(4) {
    grid-column: 1 / 4;
} */
/*ТТС */
/* .mainPartners > div:nth-child(5) {
    grid-column: 4 / 7;
} */

/*правила с 6 элемента, чтобы сетка не ломалась из-за ВК и ТТС  */
/* .mainPartners > div:nth-child(3n + 6) {
    grid-column: 1 / 3;
}

.mainPartners > div:nth-child(3n + 7) {
    grid-column: 3 / 5;
}

.mainPartners > div:nth-child(3n + 8) {
    grid-column: 5 / 7;
} */

.mainPartners > div img {
    max-width: 250px;
}

.mainPartners > .mainPartners__templates:first-child {
    grid-column: 1/-1;
    position: relative;
}

.mainPartners .mainPartners__templates > img {
    width: 114px;
    position: absolute;
    right: -40px;
    top: 40px;
}
@media (max-width: 768px) {
    .mainPartners {
        grid-template-columns: 1fr;
    }
    .mainPartners > div {
        grid-column: initial !important;
    }
}

@media (max-width: 576px) {
    .mainPartners__templates > img {
        right: -33px;
        top: 25px;
    }
    .mainPartners > div:last-child > img {
        display: none;
    }
}
