.promoBlock {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    grid-gap: 30px;
    width: 66%;
    padding: 20px 0 20px 30px;
    color: var(--kdwsite-color-white);
    background: var(--kdwsite-color-green);
    backdrop-filter: blur(5px);
    margin: 0;
}

.promoBlock * {
    box-sizing: border-box;
}

.promoBlock__wrapper {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
}

.promoBlock__header {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 36px;
    background: url("icon/stend.svg") no-repeat 55% 80% / auto 60%;
    flex: 2 1;
}

.promoBlock__header img {
    height: 167px;
    position: relative;
    left: 50px;
}

.promoBlock__privilege {
    flex: 3 1;
}

.promoBlock__privilege > span {
    font-size: 1.25rem;
    font-weight: 700;
}

.promoBlock__privilege ul {
    list-style-type: none;
    padding-left: 0;
    margin: 5px 0;
}
.promoBlock__privilege li {
    margin: 14px 0;
    display: flex;
    align-items: center;
    grid-gap: 15px;
}
.promoBlock__privilege li span {
    width: 85%;
}

.promoBlock__moreBlock {
    width: 34%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    grid-gap: 20px;
    padding: 20px 0;
    background-image: url("img/partners.webp");
    background-size: cover;
    background-repeat: no-repeat;
    transition: 0.25s ease-out;
    opacity: 0.8;
}
.promoBlock__moreBlock:hover {
    opacity: 0.94;
}
.promoBlock__more {
    display: flex;
    align-items: center;
    justify-content: stretch;
    grid-gap: 10px;
}

.promoBlock__more span {
    word-break: break-word;
    width: 70%;
}

.promoBlock__question {
    height: 100%;
    position: relative;
    padding: 14px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-gap: 50px;
}
.promoBlock__question img {
    position: absolute;
    width: 114px;
    right: -26px;
    bottom: -45px;
}

.promoBlock__contacts {
    position: relative;
    font-size: 0.875rem;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    color: var(--kdwsite-color-white);
}

.promoBlock__question .promoBlock__more span {
    font-weight: 700;
    font-size: 1.625rem;
    color: inherit;
    max-width: 67%;
}

.promoBlock__contacts div {
    font-weight: 600;
    font-size: 1.25rem;
    display: flex;
    grid-gap: 10px;
    align-items: center;
}
.promoBlock__contacts a {
    word-break: break-all;
    color: inherit;
    text-decoration: none;
}

.promoBlock__contacts > svg {
    height: 100%;
    position: absolute;
    right: 0;
    width: 30px;
}

.promoBlock__contacts > div svg path {
    fill: var(--kdwsite-color-white);
    transition: 0.25s ease-out;
}

.promoBlock__contacts > div:hover svg path {
    fill: var(--kdwsite-color-grey-hover);
}

@media (max-width: 1200px) {
    .promoBlock__contacts a {
        font-size: 1rem;
    }
}

@media (max-width: 991px) {
    .promoBlock {
        flex-direction: column;
        width: 60%;
    }
    .promoBlock__moreBlock {
        width: 40%;
    }
    .promoBlock > div {
        width: 100%;
    }
    .promoBlock__header {
        background: none;
    }
    .promoBlock__header img {
        display: none;
    }
}

@media (max-width: 768px) {
    .promoBlock__wrapper {
        flex-direction: column;
    }
    .promoBlock__wrapper > div {
        width: 100%;
    }
    .promoBlock__header,
    .promoBlock__contacts span {
        font-size: 1.4rem;
    }
}

@media (max-width: 576px) {
    .promoBlock__contacts > svg {
        display: none;
    }
    .promoBlock__more {
        flex-direction: column;
        align-items: flex-start;
    }
    .promoBlock__more span {
        width: 100%;
    }
    .promoBlock__question span {
        max-width: none;
    }
}
