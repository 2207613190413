.commonContainer {
    /*max-width: 1440px;*/
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 80px;
}

@media only screen and (min-width: 1600px) {
    .commonContainer {
        padding: 0 10%;
    }
}

/*@media only screen and (max-width: 768px) {*/
/*    .commonContainer {*/
/*        max-width: 608px;*/
/*    }*/
/*}*/

@media (max-width: 767px) {
    .commonContainer {
        /*max-width: 100%;*/
        padding: 0 20px;
    }
}
