.partners__main {
    height: 85%;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
}

.partners__main * {
    box-sizing: border-box;
}

@media (max-width: 576px) {
    .partners__main {
        height: initial;
    }
}

@media (min-width: 577px) and (max-width: 767px) {
    .partners__main {
        height: initial;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .partners__main {
        height: initial;
    }
}
