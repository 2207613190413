.exponentCard {
    display: flex;
    grid-gap: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: inherit;
    width: 100%;
}

.exponentCard div {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.exponentCard__img {
    max-height: 90px;
    width: 100%;
    max-width: 190px;
    object-fit: contain;
}

.exponentCard__name {
    text-align: center;
    max-width: 280px;
    color: inherit;
    font-weight: normal;
    font-size: 1rem;
}

@media only screen and (max-width: 992px) {
    .exponentCard {
        height: initial;
    }
}
