.footer {
    background: var(--kdwsite-color-darkgrey);
    font-weight: 400;
}

.footer * {
    box-sizing: border-box;
}

.footer__darkBg {
    padding: 20px 0;
}

.footer__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    color: var(--kdwsite-color-grey);
    grid-gap: 12px;
    margin-bottom: 12px;
}

.footer__inner > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    grid-gap: 10px;
    font-size: 0.75rem;
}

.footer__row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 20px;
}

.footer__row > a,
.footer__row > span {
    width: initial;
}

.footer__row div {
    height: 85px;
}

.footer__row div img {
    width: initial;
    height: 60px;
    max-width: 250px;
}

.footer__group_links {
    background-color: transparent;
    border-top: 1px solid rgba(217, 217, 217, 0.7);
}

.footer__social {
    /*margin: 14px 0 0;*/
    color: var(--kdwsite-color-grey);
    font-size: 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    grid-gap: 0.375rem;
}

.footer__social div {
    /* margin-top: 6px; */
    margin-bottom: 6%;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 42px;
}

.footer__social div svg {
    transition: 0.25s ease-out;
    fill: var(--kdwsite-color-grey);
}

.footer__social div svg:hover {
    fill: var(--kdwsite-color-white);
}

.footer__maintanceInner {
    margin-bottom: 0;
}

.footer__maintanceInner > div:first-child {
    justify-content: flex-start;
}

.footer__maintanceContacts {
    margin: 0;
    list-style-type: none;
    padding: 0;
}
.footer__maintanceContacts li {
    color: var(--kdwsite-color-grey);
    margin: 0.375rem 0;
    display: flex;
    align-items: center;
    grid-gap: 0.375rem;
    transition: 0.25s ease-out;
}
.footer__maintanceContacts li:first-child {
    margin-top: 0;
}
.footer__maintanceContacts li:last-child {
    margin-bottom: 0;
}

.footer__maintanceContacts li a {
    text-decoration: none;
    color: inherit;
}

.footer__maintanceContacts svg {
    transition: 0.25s ease-out;
}

.footer__maintanceContacts li:hover {
    color: var(--kdwsite-color-white);
}

.footer__maintanceContacts svg stop {
    transition: 0.25s ease-out;
    stop-color: var(--kdwsite-color-grey);
}

.footer__maintanceContacts li:hover svg stop {
    stop-color: var(--kdwsite-color-white);
}

@media (max-width: 992px) {
    .footer__inner {
        grid-gap: 1rem;
        justify-content: space-around;
    }
    .footer__inner > div {
        width: 100%;
    }
    .footer__row div img {
        max-width: 180px;
    }
}
@media (max-width: 768px) {
    .footer__row div img {
        max-width: 150px;
    }
}
@media (max-width: 576px) {
    .footer__row div img {
        max-width: 122px;
    }
}
