.footer__bottomRow {
    padding: 18px 0 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: row;
    grid-gap: 12px;
}

.footer__link {
    background-color: transparent;
    text-decoration: none;
    font-size: inherit;
    line-height: inherit;
    color: var(--kdwsite-color-grey);
    transition: 0.25s ease-out;
    justify-self: center;
}



.footer__link:last-child {
    justify-self: flex-end;
}

.footer__link:first-child {
    justify-self: flex-start;
}

.footer__link:hover {
    text-decoration: none;
    color: var(--kdwsite-color-white);
}

@media (max-width: 1200px) {
    .footer__bottomRow {
        grid-gap: 12px 16px;
    }
}

@media only screen and (max-width: 992px) {
    .footer__link {
        font-size: 0.875rem;
        justify-self: flex-start;
    }
    .footer__link:first-child,
    .footer__link:last-child {
        justify-self: inherit;
    }
    .footer__bottomRow {
        grid-template-columns: 1fr;
        grid-gap: 20px 16px;
    }
}
