.otherPartners {
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-gap: 20px;
    flex-wrap: wrap;
}

.otherPartners__inner {
    flex-grow: 1;
    height: 100%;
    overflow-y: hidden;
    /*padding-bottom: 20px;*/
}

/* .otherPartners__inner > div {
    padding: 20px;
} */

.otherPartners__partner {
    margin-bottom: 60px;
    color: var(--kdwsite-color-darkgrey);
}

.otherPartners__partner > div:last-child > a,
.otherPartners__partner > div:last-child > span {
    padding: 5px;
}

.otherPartners__partner > div:last-child > a:hover,
.otherPartners__partner > div:last-child > span:hover {
    transition: 0.25s ease-out;
    border-radius: 10px;
    box-shadow: 0 0 8px 0 rgba(111, 111, 111, 0.25);
}

.otherPartners__partner > div:last-child {
    display: flex;
    grid-gap: 30px;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
}

.otherPartners__partner > div:last-child > a,
.otherPartners__partner > div:last-child > span {
    width: 22%;
}

.otherPartners__partner > div:last-child:has(a:only-child) {
    justify-content: center;
}

.otherPartners__partnerHeader {
    display: flex;
    justify-content: center;
    color: var(--kdwsite-color-darkgrey);
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 40px;
}
.otherPartners__partnerHeader hr {
    height: 1px !important;
    background: var(--kdwsite-color-grey) !important;
    flex-grow: 1;
}

.otherPartners__border {
    padding: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
}
.otherPartners__border > div:last-child {
    justify-content: center;
}

.otherPartners__gradient {
    color: transparent;
    background: var(--kdwsite-green-background);
    -webkit-background-clip: text;
    font-weight: 700;
}
.otherPartners__gradient hr {
    background: var(--kdwsite-green-background) !important;
}
@media (min-width: 768px) and (max-width: 991px) {
    .otherPartners__partner > div:last-child {
        padding: 0 3%;
    }
}
@media (min-width: 577px) and (max-width: 767px) {
    .otherPartners__partner > div:last-child {
        padding: 0 6%;
    }
    .otherPartners__partner > div:last-child > a,
    .otherPartners__partner > div:last-child > span {
        width: 45%;
    }
}

@media (max-width: 576px) {
    .otherPartners__partner > div:last-child > a,
    .otherPartners__partner > div:last-child > span {
        width: 100%;
    }
}
