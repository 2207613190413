.partnerCard {
    padding: 10px 20px;
    color: var(--kdwsite-color-white);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    grid-gap: 10px;
    border-radius: 10px;
    flex-grow: 1;
    text-align: center;
}

.partnerCardDisabled {
    display: flex;
    color: var(--kdwsite-color-grey);
}

.partnerCard > div {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    grid-gap: 50px;
    height: 100%;
}

.partnerCard__general {
    flex-grow: 0;
    font-weight: 700;
    background: var(--kdwsite-color-white);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    color: transparent;
}

.partnerCard > span {
    font-size: 1.125rem;
}

@media (max-width: 576px) {
    .partnerCard {
        align-items: center;
    }
    .partnerCardDisabled > div > span:not(:first-child) {
        display: none;
    }
}
@media (min-width: 577px) and (max-width: 767px) {
    .partnerCard {
        align-items: center;
    }
    .partnerCardDisabled > div > span:not(:first-child) {
        display: none;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .partnerCard {
        align-items: center;
    }
    .partnerCardDisabled > div > span:not(:first-child) {
        display: none;
    }
}
